<template>
  <div class="professor">
    <!-- <img src="~@/assets/img/images/professor.png" alt="" /> -->
    <img
      src="https://platform-1309634311.cos.ap-shanghai.myqcloud.com/public/website/professor.png"
      alt=""
    />
    <div class="container-box dv-position">
      <div class="detail-box">
        <h5>丁彦青</h5>
        <p class="p1">
          南方医科大学南方医院病理科主任，从事病理工作和研究五十载，主持及完<br />成过多项国家基金重点项目，享受国务院政府津贴
        </p>
        <div class="boli">
          <h6>学术任职</h6>
          <p>※ 第三届中国医师协会病理科医师分会会长</p>
          <p>※ 第十一届中华医学会病理学分会副主任委员</p>
          <p>※ 第六届中国抗癌协会肿瘤转移专业委员会副主任委员</p>
          <p>※ 第四届诊断病理学杂志副总编辑</p>
          <p>※ 国际病理协会中国区分会副会长</p>
          <p>※ 世界华人检验与病理医师协会副会长</p>
          <p>※ 吴介平基金会病理学部副主任委员</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.professor {
  // margin-top: 65px;
  width: 100%;
  position: relative;
  // height: 600px;
  // height: auto;
  // background: url("~@/assets/img/images/professor.png") no-repeat;
  // background-size: 100% 100%;
  img {
    width: 100%;
    height: auto;
    min-height: 430px;
  }
  .dv-position {
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    .detail-box {
      // display: flex;
      // flex-direction: column;
      // align-content: center;
    }
    h5 {
      // padding-top: 45px;
      padding-bottom: 8px;
      font-size: 42px;
      color: #fff;
      // font-weight: bold;
    }
    .p1 {
      font-size: 16px;
      line-height: 26px;
      color: #fff;
    }
    .boli {
      margin-top: 35px;
      width: 460px;
      height: 260px;
      background: url("~@/assets/img/images/boli.png") no-repeat;
      background-size: 100% 100%;
      font-size: 14px;
      color: #fff;
      line-height: 28px;
      h6 {
        font-size: 16px;
        padding-top: 3px;
        padding-left: 15px;
        padding-bottom: 15px;
      }
      p {
        padding-left: 24px;
      }
    }
  }
}

// @media screen and (max-width: 1680px) {
//   .professor {
//     width: 1680px;
//     height: 525px;
//   }
// }

// @media screen and (max-width: 1600px) {
//   .professor {
//     width: 1600px;
//     height: 500px;
//   }
// }

// @media screen and (max-width: 1580px) {
//   .professor {
//     width: 1580px;
//     height: 493.75px;
//   }
// }

// @media screen and (max-width: 1480px) {
//   .professor {
//     width: 1480px;
//     height: 462.5px;
//   }
// }

// @media screen and (max-width: 1380px) {
//   .professor {
//     width: 1380px;
//     height: 431.25px;
//   }
// }
</style>
