<template>
  <div class="home">
    <TopBg :imgList="imgList" />
    <RemoteCo />
    <Professor />
    <Clients />
    <MapView />
    <Footer />
  </div>
</template>

<script>
import TopBg from "@/components/TopBg.vue";
import RemoteCo from "@/components/RemoteCo.vue";
import Professor from "@/components/Professor.vue";
import Clients from "@/components/Clients.vue";
import MapView from "@/components/MapView.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Home",
  components: {
    TopBg,
    RemoteCo,
    Professor,
    Clients,
    MapView,
    Footer
  },
  data() {
    return {
      imgList: [
        {
          id: 1,
          title1: "数字病理",
          title2: "云上视联",
          text:
            "以全国病理专家丁彦青教授为主导，提供信息化、数字化、智能化病理解决方案，通过远程协同、病理科共建、病理人工智能诊断推动病理学科建设",
          // icon: require("../assets/img/images/banner.png")
          icon:
            "https://platform-1309634311.cos.ap-shanghai.myqcloud.com/public/website/banner.png"
        }
      ]
    };
  },
  created() {}
};
</script>
