<template>
  <div class="remote-co">
    <div class="remote-bg">
      <!-- <img src="~@/assets/img/images/xt.png" alt="" /> -->
      <img
        src="https://platform-1309634311.cos.ap-shanghai.myqcloud.com/public/website/xt.png"
        alt=""
      />
      <div class="container-box card-box">
        <h6>远程协同平台</h6>
        <p class="subtitle">平台的建设基于服务器部署在中心医院端</p>
        <ul>
          <li class="card-item">
            <div class="box-card">
              <div class="card-face card-front">
                <img src="~@/assets/img/images/xt2.png" alt="" />

                <div class="dv-position">
                  <img
                    class="front-icon"
                    src="~@/assets/img/images/front-icon2.png"
                    alt=""
                  />
                  <p class="p-title">细胞学病理AI辅助诊断</p>
                </div>
              </div>
              <div class="card-face card-back">
                <img src="~@/assets/img/images/xt2.png" alt="" />
                <div class="dv-position">
                  <!-- <img
                    class="back-icon"
                    src="~@/assets/img/images/back-icon2.png"
                    alt=""
                  /> -->
                  <p class="p-title p-back-title">细胞学病理AI辅助诊断</p>
                  <p class="p-text">
                    对脱落细胞样本的显微图像进行观察、筛选、标记及分析，利用计算机辅助医生进行细胞学诊断
                  </p>
                  <div class="view-detail" @click="goAssignBlock('block0', 0)">
                    <span>查看详情</span>
                    <img src="~@/assets/img/images/right.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="card-item">
            <div class="box-card">
              <div class="card-face card-front">
                <img src="~@/assets/img/images/xt1.png" alt="" />
                <div class="dv-position">
                  <img
                    class="front-icon"
                    src="~@/assets/img/images/front-icon1.png"
                    alt=""
                  />
                  <p class="p-title">远程病理诊断</p>
                </div>
              </div>
              <div class="card-face card-back">
                <img src="~@/assets/img/images/xt1.png" alt="" />
                <div class="dv-position">
                  <!-- <img
                    class="back-icon"
                    src="~@/assets/img/images/back-icon1.png"
                    alt=""
                  /> -->
                  <p class="p-title p-back-title">远程病理诊断</p>
                  <p class="p-text">
                    将数字化切片上传到平台，专家通过平台完成阅片、诊断、签发报告后回传
                  </p>
                  <div class="view-detail" @click="goAssignBlock('block1', 1)">
                    <span>查看详情</span>
                    <img src="~@/assets/img/images/right.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li class="card-item">
            <div class="box-card">
              <div class="card-face card-front">
                <img src="~@/assets/img/images/xt4.png" alt="" />
                <div class="dv-position">
                  <img
                    class="front-icon"
                    src="~@/assets/img/images/front-icon4.png"
                    alt=""
                  />
                  <p class="p-title">远程会诊系统</p>
                </div>
              </div>
              <div class="card-face card-back">
                <img src="~@/assets/img/images/xt4.png" alt="" />
                <div class="dv-position">
                  <!-- <img
                    class="back-icon"
                    src="~@/assets/img/images/back-icon4.png"
                    alt=""
                  /> -->
                  <p class="p-title p-back-title">远程会诊系统</p>
                  <p class="p-text">
                    医生通过PC端或移动端远程在线沟通疑难病例，汇总意见最后形成会诊建议
                  </p>
                  <div class="view-detail" @click="goAssignBlock('block2', 2)">
                    <span>查看详情</span>
                    <img src="~@/assets/img/images/right.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li class="card-item">
            <div class="box-card">
              <div class="card-face card-front">
                <img src="~@/assets/img/images/xt5.png" alt="" />
                <div class="dv-position">
                  <img
                    class="front-icon"
                    src="~@/assets/img/images/front-icon5.png"
                    alt=""
                  />
                  <p class="p-title">远程医学教育</p>
                </div>
              </div>
              <div class="card-face card-back">
                <img src="~@/assets/img/images/xt5.png" alt="" />
                <div class="dv-position">
                  <!-- <img
                    class="back-icon"
                    src="~@/assets/img/images/back-icon5.png"
                    alt=""
                  /> -->
                  <p class="p-title p-back-title">远程医学教育</p>
                  <p class="p-text">
                    系统用于教学指导、业务学习、技能培训和行业会议课程的录播、点播
                  </p>
                  <div class="view-detail" @click="goAssignBlock('block3', 3)">
                    <span>查看详情</span>
                    <img src="~@/assets/img/images/right.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="card-item">
            <div class="box-card">
              <div class="card-face card-front">
                <img src="~@/assets/img/images/xt6.png" alt="" />
                <div class="dv-position">
                  <img
                    class="front-icon"
                    src="~@/assets/img/images/front-icon6.png"
                    alt=""
                  />
                  <p class="p-title">远程胎心监护</p>
                </div>
              </div>
              <div class="card-face card-back">
                <img src="~@/assets/img/images/xt6.png" alt="" />
                <div class="dv-position">
                  <!-- <img
                    class="back-icon"
                    src="~@/assets/img/images/back-icon6.png"
                    alt=""
                  /> -->
                  <p class="p-title p-back-title">远程胎心监护</p>
                  <p class="p-text">
                    运用监测仪和移动终端，远程胎心监护实时由专业医生远程判图，大大提高了产检质量
                  </p>
                  <div class="view-detail" @click="goAssignBlock('block4', 4)">
                    <span>查看详情</span>
                    <img src="~@/assets/img/images/right.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="card-item">
            <div class="box-card">
              <div class="card-face card-front">
                <img src="~@/assets/img/images/xt3.png" alt="" />
                <div class="dv-position">
                  <img
                    class="front-icon"
                    src="~@/assets/img/images/front-icon3.png"
                    alt=""
                  />
                  <p class="p-title">远程病理质控</p>
                </div>
              </div>
              <div class="card-face card-back">
                <img src="~@/assets/img/images/xt3.png" alt="" />
                <div class="dv-position">
                  <!-- <img
                    class="back-icon"
                    src="~@/assets/img/images/back-icon3.png"
                    alt=""
                  /> -->
                  <p class="p-title p-back-title">远程病理质控</p>
                  <p class="p-text">
                    支持多客户端多终端登录，远程实时阅片签发病理诊断报告，打破时空限制；独创算法质控样本质量
                  </p>
                  <div class="view-detail" @click="goAssignBlock('block5', 5)">
                    <span>查看详情</span>
                    <img src="~@/assets/img/images/right.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li class="card-item">
            <div class="box-card">
              <div class="card-face card-front">
                <img src="~@/assets/img/images/xt8.png" alt="" />
                <div class="dv-position">
                  <img
                    class="front-icon"
                    src="~@/assets/img/images/front-icon8.png"
                    alt=""
                  />
                  <p class="p-title">健康宣教</p>
                </div>
              </div>
              <div class="card-face card-back">
                <img src="~@/assets/img/images/xt8.png" alt="" />
                <div class="dv-position">
                  <p class="p-title p-back-title">健康宣教</p>
                  <p class="p-text">
                    上传和管理健康科普类知识库，智能推送健康宣教内容至患者手机，提高患者健康意识
                  </p>
                  <div class="view-detail" @click="goAssignBlock('block6', 6)">
                    <span>查看详情</span>
                    <img src="~@/assets/img/images/right.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="card-item">
            <div class="box-card">
              <div class="card-face card-front">
                <img src="~@/assets/img/images/xt7.png" alt="" />
                <div class="dv-position">
                  <img
                    class="front-icon"
                    src="~@/assets/img/images/front-icon7.png"
                    alt=""
                  />
                  <p class="p-title">医生社区</p>
                </div>
              </div>
              <div class="card-face card-back">
                <img src="~@/assets/img/images/xt7.png" alt="" />
                <div class="dv-position">
                  <!-- <img
                    class="back-icon"
                    src="~@/assets/img/images/back-icon7.png"
                    alt=""
                  /> -->
                  <p class="p-title p-back-title">医生社区</p>
                  <p class="p-text">
                    提供医学讲座和医学咨询，更新医生临床相关知识，提升用户接触医学讲座的可及性和便利性
                  </p>
                  <div class="view-detail" @click="goAssignBlock('block7', 7)">
                    <span>查看详情</span>
                    <img src="~@/assets/img/images/right.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      delay: 0
    };
  },
  methods: {
    goAssignBlock(el, index) {
      if (this.$route.name !== "RemoteCollaborative") {
        this.delay = 100;
        this.$router.push({
          path: "/remoteCollaborative"
        });
      }
      setTimeout(() => {
        this.delay = 0;
        this.$parent.$parent.$children[2].$children[index].$refs[
          el
        ].scrollIntoView(true);
      }, this.delay);
      // let _this = this;
      // setTimeout(() => {
      //   this.delay = 0;
      //   this.$nextTick(() => {
      //     let dom = _this.$parent.$parent.$children[2].$children[index].$refs;
      //     let windowH = window.innerHeight;
      //     let h = dom[el].offsetHeight;
      //     console.info(h);
      //     let t = dom[el].offsetTop;
      //     // let top = t - (windowH - h) / 2;
      //     let top = t;
      //     let scrollTop =
      //       window.pageYOffset ||
      //       document.documentElement.scrollTop ||
      //       document.body.scrollTop;
      //     let currentTop = scrollTop;
      //     let requestId;
      //     function step() {
      //       if (scrollTop < top) {
      //         if (currentTop <= top) {
      //           window.scrollTo(0, currentTop);
      //           requestId = window.requestAnimationFrame(step);
      //         } else {
      //           window.cancelAnimationFrame(requestId);
      //         }
      //         //向下滚动
      //         currentTop += speed;
      //       } else {
      //         if (top <= currentTop) {
      //           window.scrollTo(0, currentTop - speed);
      //           requestId = window.requestAnimationFrame(step);
      //         } else {
      //           window.cancelAnimationFrame(requestId);
      //         }
      //         currentTop -= speed;
      //       }
      //     }
      //     window.requestAnimationFrame(step);
      //   });
      // }, this.delay);
    }
  }
};
</script>

<style lang="scss" scoped>
.remote-co {
  width: 100%;
  height: 755px;
  background: linear-gradient(180deg, #ffffff 0%, #eef5ff 47.4%, #eef5ff 100%);
}
.remote-bg {
  position: relative;
  width: 100%;
  height: auto;
  img {
    width: 100%;
    height: 100%;
  }

  .card-box {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    h6 {
      text-align: center;
      font-size: 32px;
      color: #fff;
      padding-top: 60px;
    }
    .subtitle {
      padding-top: 10px;
      font-size: 14px;
      text-align: center;
      color: #fff;
    }
    ul {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;

      li {
        cursor: pointer;
        margin-right: 24px;
        width: 262px;
        height: 244px;
        margin-bottom: 24px;
        perspective: 1000px;
        .box-card {
          transform-style: preserve-3d;
          transition: all 0.3s;
          position: relative;
          width: 100%;
          height: 100%;
        }

        .card-face {
          position: absolute;
          top: 0px;
          left: 0px;
          backface-visibility: hidden;
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .card-front {
          box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.4);
          background-color: #fff;
        }
        .card-back {
          transform: rotateY(-180deg);
          -webkit-transform: rotateY(-180deg);
          -moz-transform: rotateY(-180deg);
          -o-transform: rotateY(-180deg);
          -ms-transform: rotateY(-180deg);
          background-color: rgba(0, 122, 255, 1);
        }
        .dv-position {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          padding: 24px;
          .front-icon {
            width: 64px;
            height: 64px;
          }
          .back-icon {
            width: 24px;
            height: 24px;
          }
          .p-title {
            padding-top: 10px;
            color: #262626;
            font-size: 20px;
            line-height: 26px;
          }
          .p-back-title {
            color: #fff;
          }
          .p-text {
            padding-top: 10px;
            color: #fff;
            font-size: 16px;
            line-height: 22px;
          }
          .view-detail {
            font-size: 12px;
            color: #fff;
            position: absolute;
            bottom: 24px;
            left: 24px;
            display: flex;
            align-content: center;
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
      li:nth-child(4n) {
        margin-right: 0;
      }
      li:hover .box-card {
        transform: rotateY(-180deg);
        -webkit-transform: rotateY(-180deg);
        -moz-transform: rotateY(-180deg);
        -o-transform: rotateY(-180deg);
        -ms-transform: rotateY(-180deg);
        /* background-color: rgba(0, 122, 255, 1); */
      }
    }
  }
}
</style>
