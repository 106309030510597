<template>
  <div class="clients">
    <div class="container-box">
      <h6 class="title-font">客户案例</h6>
      <div class="p-mask"></div>
      <swiper :options="swiperOption" ref="mySwiper" class="ul">
        <swiper-slide v-for="item in list" :key="item.id" class="li">
          <div class="dvimg">
            <img :src="item.icon" alt="" />
          </div>
        </swiper-slide>
      </swiper>
      <!-- <div class="dv-more">
        查看更多案例>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      swiperOption: {
        autoplay: {
          stopOnLastSlide: false,
          disableOnInteraction: false,
          delay: 1
        },
        speed: 3000,
        loop: true,
        // freeMode: true,
        slidesPerView: 3,
        slidesPerGroup: 1
      },
      list: [
        {
          id: 1,
          icon: require("../assets/img/images/an1.png")
        },
        {
          id: 2,
          icon: require("../assets/img/images/an2.png")
        },
        {
          id: 3,
          icon: require("../assets/img/images/an3.png")
        },
        // {
        //   id: 4,
        //   icon: require("../assets/img/images/an4.png")
        // }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.clients {
  // background: #fff;
  width: 100%;
  padding-top: 50px;
  position: relative;
  p {
    font-size: 16px;
    color: #999;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 60px;
  }
  .p-mask {
    position: absolute;
    top: 0;
    left: -1px;
    right: -1px;
    bottom: 0;
    z-index: 11;
    width: 99%;
    background: linear-gradient(
      90deg,
      #fff,
      hsla(0, 0%, 100%, 0.3) 24%,
      hsla(0, 0%, 100%, 0) 46%,
      hsla(0, 0%, 100%, 0.3) 72%,
      #fff
    );
  }
  .ul {
    margin-top: 32px;
    .li {
      display: flex;
      align-items: center;
      justify-content: center;

      .dvimg {
        background: #fff;
        border: 1px solid #d9d9d9;
        width: 262px;
        height: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .dv-more {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #333;
    cursor: pointer;
  }
}

/deep/ .swiper-wrapper {
  display: flex;
  align-items: center;
}
</style>
